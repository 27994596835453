// import { getUrlParam } from './tool';
import test_host from './host_config/test';
import staging_host from './host_config/staging';
import dev_host from './host_config/dev';

// let domain = "http://bi-udata.api.baimaodai.cn/";
// let browser_host = window.location.hostname;
// let login="http://auth.baimaodai.cn";
// if(browser_host==="open.zhiyoou.com"){
// 	domain = "http://merchant.api.zhiyoou.com";
// }else if(browser_host==="open.heimaodai.com"){
// 	domain = "http://merchant.api.heimaodai.com";
// }else{
// 	// domain = "http://open.baimaodai.cn:8080/";
// 	domain="http://merchant.api.baimaodai.cn";
// 	// domain=""
// }

const hostname = window.location.hostname;
let domain = hostname.split(".").splice("-2").join(".");

let origin = {};
switch(domain){
    case "baimaodai.cn":
        origin = test_host.origin;
        break;
    case "heimaodai.com":
        origin = staging_host.origin;
        break;
    case "baimaodai.com":
        origin = dev_host.origin;
        break;
    default:
        origin = test_host.origin;
}

// 通用host
export const host = origin.udata;
export const monitor_host = origin.monitor;
export const login_page=origin.auth+"/auth/login";

// 列表每页条数
export const page = {size:50}

// 分期订单状态
export const feeType=[{name:"本金",val:"Principal",color:"#6699FF"},{name:"利息",val:"Interest",color:"#FF6666"},{name:"服务费",val:"ServiceFee",color:"#9999CC"},{name:"其他费用",val:"OtherFee",color:"#66CC66"},{name:"逾期罚息",val:"OverdueInterest",color:"#FFCC66"},{name:"违约金",val:"PenaltyOverdueFee",color:"#66CCCC"},{name:"提前结清手续费",val:"PenaltyAheadFee",color:"#CC6666"},]
export const count=[{
    name:"订单总数",val:"OrderCount"
},{name:"总期数",val:"PhaseCount"}]
export const countOverdue=[{
    name:"订单总数",val:"orderCount"
},{name:"总期数",val:"phaseCount"}]
export const overdueStr=[{val:"overdue_1_",name:"1天",color:"#6699FF"},{val:"overdue_2_3_",name:"2~3天",color:"#FF6666"},{val:"overdue_4_7_",name:"4~7天",color:"#9999CC"},{val:"overdue_8_14_",name:"8~14天",color:"#66CC66"},{val:"overdue_15_21_",name:"15~21天",color:"#FFCC66"},{val:"overdue_22_30_",name:"22~30天",color:"#66CCCC"},{val:"overdue_31_60_",name:"31~60天",color:"#CC6666"},{val:"overdue_61_90_",name:"61~90天",color:"#FF6699"},{val:"overdue_91_120_",name:"91~120天",color:"#FF9966"},{val:"overdue_121_150_",name:"121~150天",color:"#FF66FF"},{val:"overdue_151_180_",name:"151~180天",color:"#CC6666"},{val:"overdue_180p_",name:"180天以上",color:"#66CCFF"}]
export const newString=e=>{
    var string=["Principal","Interest","ServiceFee","OtherFee","OverdueInterest","PenaltyOverdueFee","PenaltyAheadFee"],newstring=[];
    for(var i in string){
        newstring.push(e+string[i]);
    }
    return newstring
}
export const limit=[{val:"limit_under_1000",name:"额度在1000元以下",color:"#6699FF"},{val:"limit_1000_2000",name:"额度在1000元~2000元",color:"#FF6666"},{val:"limit_2000_3000",name:"额度在2000元~3000元",color:"#9999CC"},{val:"limit_3000_4000",name:"额度在3000元~4000元",color:"#66CC66"},{val:"limit_4000_5000",name:"额度在4000元~5000元",color:"#FFCC66"},{val:"limit_5000_7500",name:"额度在5000元~7500元",color:"#66CCCC"},{val:"limit_7500_10000",name:"额度在7500元~10000元",color:"#CC6666"},{val:"limit_10000_20000",name:"额度在10000元~20000元",color:"#FF6699"},{val:"limit_beyond_20000",name:"额度在20000元以上",color:"#FF9966"}]
export const group={"domain":"业务","appKey":"项目","channel":"渠道","phaseCount":"期数","isFirstLend":"是否首借","statDate":"统计日","statHour":"统计时间","platform":"注册平台"}



