import React, { Component } from 'react';
import { Column } from '@ant-design/charts';
import { FullscreenOutlined, TableOutlined } from '@ant-design/icons';
import { Modal, Table } from 'antd';
import {sortByKey,sortDownByKey} from '../../ajax/tool';
class DemoColumn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data:props.data,
      str:props.yField,
    };
    this.columns_sort = [
      {
        title: props.name,
        dataIndex: props.xField
      },
      {
        title: props.title,
        dataIndex: props.yField
      },
    ]
  }
  componentWillMount(){
  }
  enlarge(large) {
    this.setState({
      visible: true,
      large: large,
    })
  }
  cancel() {
    this.setState({
      visible: false,
    })
    setTimeout(function () {
      this.setState({
        large: ""
      })
    }.bind(this), 100)
  }
  render() {
    console.log(this.props.data)
    var data=this.props.data;
    var dataFinal=[];
    if(this.props.search){
      data.forEach((item,index)=>{
        var arr=item.data;
        var total=0;
        arr.forEach(ele=>{
          total+=(ele[this.props.yField])
          // if(index<5){
          //   ele.name="first"
          // }else if(4<index<9){
          //   ele.name="second"
          // }else if(8<index<13){
          //   ele.name="third"
          // }else{
          //   ele.name="other"
          // }
        })
        if(total){
          dataFinal.push.apply(dataFinal,arr)
        }
      })
    }    
    const config = {
      data: this.props.search?dataFinal:(this.props.sort?(this.props.sortType?sortByKey(this.props.data,this.props.yField):sortDownByKey(this.props.data,this.props.yField)):this.props.data),
      // data:dataFinal,
      isGroup: true,
      xField: this.props.xField,
      yField: this.props.yField,
      seriesField: this.props.seriesField || null,
      loading:this.props.loading,
      label: {
        position: 'middle',
        layout: [
          {
            type: 'interval-adjust-position',
          },
          {
            // type: 'adjust-color',
          },
        ],
        // content: item => this.props.rate?(item[this.props.yField]).toFixed(2)+"%":item[this.props.yField],
        formatter:e=>{
          // return this.props.rate?e+"%":e
        }
      },
      height: 300,
      meta: {
        [this.props.yField]: { alias: this.props.title },
        [this.props.yField]: { formatter: e=>{return this.props.rate?e+"%":e} },
        [this.props.xField]:this.props.data.length>0?{formatter: e=>{ return e.replace(/_/g,"-")} }:null
      },
      xAxis: {
        label: {
          // autoRotate: false,
          formatter:e=>{
            return e.replace(/_/g,"-")
          }
        },
      },
      slider: {
        start: 0,
        end: 1,
        formatter:e=>{ return e.replace(/_/g,"-")} 
      },
      yAxis: {
        max: this.props.rate ? 100 : null,
      },
      // color:['red'],
      columnStyle:{
        // fill:this.props.dimension!=="NONE"?"":'#1890ff'
      },
      isStack:true,
      // groupField:"name",
    };
    const modalInfo = {
      visible: this.state.visible,
      title: this.props.title,
      footer: null,
      onCancel: this.cancel.bind(this),
      width: "90%"
    }
    const columns_sort = [
      {
        title: this.props.name,
        dataIndex: this.props.xField
      },
      {
        title: this.props.title,
        dataIndex: this.props.yField
      },
    ]
    const columns = [
      {
        title: "时间",
        dataIndex: this.props.xField
      },
      {
        title: this.props.source?"数据源":"第三维度",
        dataIndex: this.props.seriesField,
        render: e => e || "无"
      },
      {
        title: this.props.title,
        dataIndex: this.props.yField
      },
    ]
    const showType = {
      chart: <Column {...config} />,
      table: <Table dataSource={this.props.data} columns={this.props.sort?columns_sort:columns} pagination={false} />,
    }
    return <div style={{ width: "50%", padding: "10px 20px", float: "left" }}>
      <div className="chartTitle">{this.props.title}
        <FullscreenOutlined style={{ float: "right" }} onClick={() => { this.enlarge("chart") }} />
        <TableOutlined style={{ float: "right", marginRight: "10px" }} onClick={() => { this.enlarge("table") }} />
      </div>
      {this.props.search?(dataFinal.length>0?<Column {...config} />:null):<Column {...config} />}
      <Modal {...modalInfo}>{showType[this.state.large]}</Modal>
    </div>;
  }

};

export default DemoColumn;
