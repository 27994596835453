import React,{Component} from 'react';
import Column from '../../../templates/chartColumn';
import Line from '../../../templates/chartLine';
class DemoColumn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
    this.chartArr=[
      {
        yField:"totalCount",
        title:"总条数"
      },
      {
        yField:"chargedCount",
        title:"计费条数"
      },
      {
        yField:"revenue",
        title:"收入"
      },
      {
        yField:"cost",
        title:"成本"
      },
      {
        yField:"profit",
        title:"毛利"
      },
      {
        yField:"profitRate",
        title:"毛利率",
        rate:true
      }
    ]
}
  componentWillMount(){

  }
  
  render(){
    var data=this.props.data;
    var Modals=this.props.type === "column" ?Column:Line
    return <div style={{ overflow: "hidden" }}>
      {
        this.chartArr.map((i,k)=>{
          return <Modals data={data} xField="statsTime" yField={i.yField}  seriesField={this.props.dimension==="NONE"?null:"dimensionName"} title={i.title} rate={i.rate} loading={this.props.loading} />
        })
      }
    </div>;
  }
};

export default DemoColumn;
