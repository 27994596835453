import React,{Component} from 'react';
// import logo from './styls/img/logo.png';
import './styls/css/index.less';
import { Layout } from 'antd';
import 'antd/dist/antd.css';
import Side from './components/side';
import Router from './router';
import { BrowserRouter} from 'react-router-dom';
import './keep.op';
import './revision.op';
const { Content, Sider } = Layout;

class App extends Component {
  constructor(props) {
      super(props);console.log(props)
      this.state = {
          min_height: window.innerHeight,
          collapsed:props.location.pathname==="/chart"||props.location.pathname==="/"?true:false
      };
  }
  componentWillMount () {
      this.setState({
          min_height:window.innerHeight
      })
      localStorage.setItem("select",JSON.stringify({}));
      window.onresize = () => {
          console.log('屏幕变化了');
          this.setState({
              min_height:window.innerHeight
          })
      }
  }
  change(collapsed){
    this.setState({collapsed})
  }
  render() {
      return (
        <BrowserRouter>
          <Layout>
          {/* {router==="/chart"?null:<Sider style={{overflow: 'auto',height: '100vh',position: 'fixed',left: 0,}}>
            <div className="logo"><img src={logo} alt="" inlineCollapsed /></div>
            <Side />
          </Sider>} */}
          
          <Sider style={{height: '100vh',position: 'fixed',left: 0,zIndex:"10"}} onCollapse={this.change.bind(this)} collapsible collapsed={this.state.collapsed} collapsedWidth={0} zeroWidthTriggerStyle={{top:120}}>
            <div>
            {/* <div className="logo"><img src={logo} alt="" /></div> */}
            <Side />
            </div>
            
          </Sider>
          
          <Layout className="site-layout" style={{ marginLeft: this.state.collapsed?0:200,minHeight:this.state.min_height+'px'}}> 
            <Content style={{ margin:'10px', overflow: 'initial' }}>
              <Router />
            </Content>
          </Layout>
        </Layout>
        </BrowserRouter>
      );
  }
}

export default App;
