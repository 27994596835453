// 数据请求接口地址
const api = {
	//数据源统计
	dropdown_datasource_time:"/manage/dropdown/all_enum",
	dropdown_datasource_code:"/manage/dropdown/all_list",
	// dropdown_datasource_type:"/manage/dropdown/all_list",
	//产品统计图第三维度
	// dropdown_product_third:"/manage/dropdown/product_chart/third_latitude",
	//产品统计列表
	get_product_list:"/manage/statistics/product_chart/list",
	//数据源统计列表
	get_source_list:"/manage/statistics/data_source_chart/list",
	//数据源厂商统计列表
	get_source_supplier_list:"/manage/statistics/data_source_supplier_chart/list",

	//财务统计
	//客户经理
	// dropdown_customer_manager:"/manage/dropdown/customer_manager",
	//时间粒度
	// dropdown_time:"/manage/dropdown/time_granularity",
	//产品类型
	// dropdown_product_type:"/manage/dropdown/product_type",
	//客户名称
	// dropdown_customer:"/manage/dropdown/customer/list",
	//产品名称
	// dropdown_product:"/manage/dropdown/product/list",
	// dropdown_datasource_type:"/manage/dropdown/datasource/type",
	//财务统计第三维度
	// dropdown_financial_third:"/manage/dropdown/financial/dimension",
	//财务多维度列表页
	get_finance_list:"/manage/statistics/financial/list",
	//下载
	get_finance_download:"/manage/statistics/financial/download",
	//财务单维度 排序
	get_finance_sort:"/manage/statistics/financial/single_dimension/list",

	//monitor项目的接口
	//客群多头统计
	get_customer_group_multi_list:"/manage/customer_group_multi_stats/get_list",
}

module.exports = api ;
