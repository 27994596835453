import React,{Component} from 'react';
import Column from '../../../templates/chartColumn';
class DemoColumn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
    this.chartArr=[
      {
        yField:"totalCount",
        title:"总条数"
      },
      {
        yField:"chargedCount",
        title:"计费条数"
      },
      {
        yField:"revenue",
        title:"原始收入"
      },
      {
        yField:"cost",
        title:"原始成本"
      },
      {
        yField:"profit",
        title:"原始毛利"
      },
      {
        yField:"profitRate",
        title:"原始毛利率",
        rate:true
      },
      {
        yField:"pudaoProfit",
        title:"朴道分润"
      },
      {
        yField:"blzProfit",
        title:"避雷针利润"
      },
      {
        yField:"blzProfitRate",
        title:"避雷针毛利率",
        rate:true
      },
      {
        yField:"pudaoPayableCost",
        title:"朴道应付自有数据源成本"
      },
      {
        yField:"pudaoBlzDataSourceCost",
        title:"自有数据源真实成本",
      },
    ]
}
  componentWillMount(){
  }
  render(){
    var data=this.props.data;   
    return <div style={{ overflow: "hidden" }}>
      {
        this.chartArr.map((i,k)=>{
          var test=[];
          data.forEach(item=>{
            for(var j in item){
              if(j===i.yField){
                test.push({dimensionName:item.dimensionName,[i.yField]:item[i.yField]})
              }
            }
          })
          return <Column data={test} xField="dimensionName" yField={i.yField} title={i.title} key={k+this.chartArr.length} sort sortType={this.props.type === "column"?true:false} name={this.props.xName} rate={i.rate} loading={this.props.loading} />
        })
      }
    </div>;
  }
};

export default DemoColumn;
