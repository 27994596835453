import React,{Component} from 'react';
import Column from '../../templates/chartColumn';
import Line from '../../templates/chartLine';
class DemoColumn extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.chartArr=[
      {
        yField:"totalCount",
        title:"总条数"
      },
      {
        yField:"successCount",
        title:"成功条数"
      },
      {
        yField:"payCount",
        title:"付费条数"
      },
      {
        yField:"payRate",
        title:"付费率",
        rate:true
      },
      {
        yField:"failRate",
        title:"失败率",
        rate:true
      },
    ]
  }
  componentWillMount(){

  }
  render(){
    var arr=this.props.data;
    var map = {},
        dest = [];
    for (var i = 0; i < arr.length; i++) {
      var ai = arr[i];
      if (!map[ai.tagName]) {
        dest.push({
          tagName: ai.tagName,
          data:[ai]
        });
        map[ai.tagName] = ai;
      } else {
        for (var j = 0; j < dest.length; j++) {
          var dj = dest[j];
          if (dj.tagName === ai.tagName) {
            dj.data.push(ai);
            break;
          }
        }
      }
    }
    console.log(dest)
    console.log(arr)

    var Modals=this.props.type === "column" ?Column:Line
    return <div style={{ overflow: "hidden" }}>
      {
        this.chartArr.map((i,k)=>{
          return <Modals data={dest} xField="date" yField={i.yField}  seriesField={this.props.dimension==="NONE"?null:"tagName"} title={i.title} rate={i.rate} loading={this.props.loading} search />
        })
      }
    </div>;
  }
};
export default DemoColumn;
