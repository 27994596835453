import React, { Component } from 'react';
import { Row, Col, DatePicker, Select, Button, Input, Checkbox, message } from 'antd';
import { axios_f } from '../../ajax/request';
// import local from '../../ajax/local';
import moment from 'moment';
import { get_region } from '../../ajax/api';
const RangePicker = DatePicker.RangePicker
const Option = Select.Option;
class Filter extends Component {
    constructor(props) {
        super(props);
        this.state = { ...props, isRember: false, select_data: { aa: { child: [] } } }
        this.remberDatas = {}
    }
    componentWillMount() {
        // this.reset_data();
        if (this.props['data-set']) {
            this.props['data-set'](this);
        }
        document.addEventListener("keydown", this.onKeyDown.bind(this))
    }
    getRegion() {
        axios_f.post(get_region).then(e => {
            if (!e.code) {
                this.setState({
                    localData: e.data.list
                })
            }
            this.set_province(e.data.list);
        })
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps["data-source"] !== this.state["data-source"]) {
            this.setState({
                "data-source": nextProps["data-source"],
                time: nextProps.time
            })
        }
    }
    onKeyDown(e) {
        if (e.keyCode === 13) {
            this.get_data()
        }
    }
    textChange(e) {
        let key = e.target.getAttribute("id");
        this.setState({
            [key]: e.target.value
        })
    }
    selectChange(val, key, child) {
        this.setState({
            [key]: val
        })
        if (child) {
            this.setState({
                [child]: ""
            })
        }
    }
    dateChange(date1, date2, key) {
        this.setState({
            [key]: date1
        })
    }
    get_data(isExport) {
        let items = this.state['data-source'];
        let values = {};
        for (let i in items) {
            
            if (!this.state[i] || this.state[i].length <= 0) {
                continue;
            }
            console.log(this.state["time"])
            if(!this.state["time"]){
                message.warn("请选择时间范围");
                return;
            }
            if (i === "time") {
                
                if (this.state.tg==="YEAR"||this.state.timeGranularity==="YEAR"||this.state.timeGranularity==="QUARTER") {
                    values["beginDate"] = this.state[i] ? this.state[i][0].format("YYYY-01-01"): "";
                    values["endDate"] = this.state[i] ? this.state[i][1].format("YYYY-01-01"): "";
                }
                if(this.state.tg==="MONTH"||this.state.timeGranularity==="MONTH"){
                    values["beginDate"] = this.state[i] ? this.state[i][0].format("YYYY-MM-01"): "";
                    values["endDate"] = this.state[i] ? this.state[i][1].format("YYYY-MM-01"): "";
                }
                 if(this.state.tg==="DAY"||this.state.timeGranularity==="DAY"||this.state.tg==="HOUR"||this.state.tg==="MINUTE"||this.state.tg==="MINUTE_FIVE"){
                    values["beginDate"] = this.state[i] ? this.state[i][0].format("YYYY-MM-DD"): "";
                    values["endDate"] = this.state[i] ? this.state[i][1].format("YYYY-MM-DD"): "";
                }
                //  if(this.state.tg==="HOUR"||this.state.timeGranularity==="HOUR"){
                //     values["beginDate"] = this.state[i] ? this.state[i][0].format("YYYY-MM-DD HH:00"): "";
                //     values["endDate"] = this.state[i] ? this.state[i][1].format("YYYY-MM-DD HH:00"): "";
                // }
            }else{
                values[i] = this.state[i];
            }
        }
        this.remberDatas = values; console.log(values)
        var pathData = {};
        pathData.isRember = this.state.isRember;
        pathData.remberData = this.state.isRember ? values : {};
        window.localStorage.setItem(this.props["data-paths"], JSON.stringify(pathData));
        if (isExport) {
            this.props['data-get'](values, true);
        } else {
            this.props['data-get'](values);
        }
    }
    reset_data(e) {
        let items = this.state['data-source'];
        let null_data = {}
        for (let i in items) {
            if (items[i].type === "local") {
                null_data[items[i].province] = undefined;
                null_data[items[i].city] = undefined;
                null_data[items[i].county] = undefined;
            }
            if (!items[i].default && (!this.state[i] || this.state[i].length <= 0)) {
                continue;
            }
            null_data[i] = "";

            if (items[i].type === "time") {
                null_data[i] = items[i].default;
            } else if (items[i].type === "multi_select") {
                null_data[i] = [];
            } else if (items[i].type === "select") {
                if (items[i].resetValue) {
                    null_data[i] = items[i].resetValue;
                } else {
                    null_data[i] = "";
                }
            } else {
                document.getElementById(i).value = "";
            }
        }
        this.setState(null_data); console.log(null_data)
        setTimeout(function () {
            this.get_data();
        }.bind(this), 10)
    }
    remeber(e) {
        this.setState({
            isRember: e.target.checked
        })
        // if(e.target.checked){
        var pathData = {};
        pathData.isRember = e.target.checked;
        pathData.remberData = e.target.checked ? this.remberDatas : {};
        window.localStorage.setItem(this.props["data-paths"], JSON.stringify(pathData));
        // }
    }
    render() {
        let items = this.state["data-source"];
        let filter_elements = [];
        for (let i in items) {
            let ele = "";
            let label = (
                <Col key={i + "key"} className="element-name">
                    {items[i].name}
                </Col>
            )
            if (items[i].type === "time") {
                var timeModal = "";
                if (this.state.tg === "YEAR"||this.state.tg === "quarter"||this.state.timeGranularity==="YEAR"||this.state.timeGranularity==="QUARTER") {
                    timeModal = (
                        <Col className="element" style={{ width: "220px" }}>
                            <RangePicker placeholder={["开始时间", "结束时间"]} onChange={(date, str) => { this.dateChange(date, str, "time") }} picker={"year"} className="time" value={this.state[i]} />
                        </Col>)
                } else if ( this.state.tg === "MONTH"||this.state.timeGranularity === "MONTH") {
                    timeModal = (
                        <Col className="element" style={{ width: "220px" }}>
                            <RangePicker placeholder={["开始时间", "结束时间"]} onChange={(date, str) => { this.dateChange(date, str, "time") }} picker={"month"} className="time" value={this.state[i]} />
                        </Col>)
                } else if (this.state.tg === "week" || this.state.tg === "DAY"|| this.state.timeGranularity === "DAY"||this.state.tg==="HOUR"||this.state.tg==="MINUTE"||this.state.tg==="MINUTE_FIVE") {
                    timeModal = (
                        <Col className="element" style={{ width: "220px" }}>
                            <RangePicker placeholder={["开始时间", "结束时间"]} onChange={(date, str) => { this.dateChange(date, str, "time") }} className="time" value={this.state[i]} />
                        </Col>)
                } else if (this.state.tg === "HOURs") {
                    timeModal = (
                        <Col className="element" style={{ width: "220px" }}>
                            <RangePicker placeholder={["开始时间", "结束时间"]} onChange={(date, str) => { this.dateChange(date, str, "time") }} showTime={{ format: "HH", defaultValue: [moment("00:00", "HH:mm"), moment()] }} format="YYYY-MM-DD HH:00" className="time" value={this.state[i]} />
                        </Col>)
                }
                ele=timeModal
            } else if (items[i].type === "text") {
                // console.log(this.state[i])
                ele = (
                    <Col key={i} className="element">
                        <Input id={i} onChange={this.textChange.bind(this)} type="text" className="ipt-text" placeholder={"请输入" + items[i].name} value={this.state[i]} />
                    </Col>)
            } else if (items[i].type === "select") {
                let values = items[i].values, relevanceChild = "";
                // values = typeof values==="string"?this.state.select_data:values;
                values = typeof values === "string" ? this.props[values] : values;
                if (items[i].relevance === "parent") {
                    var newValues = [];
                    for (var val in values) {
                        var json = {
                            name: values[val].name,
                            val: values[val].value
                        }
                        newValues.push(json)
                    }
                    if (items[i].relevanceChild) {
                        relevanceChild = items[i].relevanceChild
                    }
                    values = newValues;
                } else if (items[i].relevance !== "parent" && items[i].relevance) {
                    values = (this.state[items[i].relevance]) ? (values[this.state[items[i].relevance]].child ? values[this.state[items[i].relevance]].child : []) : [];
                }
                let opt_ele = items[i].allHide?[]:[<Option key={100} value={items[i].defaultValue ? items[i].defaultValue : ""}>全部</Option>];
                for (let v in values) {
                    // if(values[v].val==="NONE"){
                    //     opt_ele=[];
                    //     opt_ele = [<Option key={100} value={items[i].defaultValue ? items[i].defaultValue : ""}>无</Option>]
                    // }
                    if (values[v].name !== "全部") {
                        opt_ele.push(<Option key={v} value={values[v].value + ""} label={values[v].name}>{values[v].name}</Option>)
                    }
                }
                let select = (<Select allowClear showSearch optionFilterProp="label" dropdownMatchSelectWidth={false} value={this.state[i] || ""} id={i} placeholder={items[i].placeHolder} onChange={(val) => { this.selectChange(val, i, relevanceChild) }}>{opt_ele}</Select>);
                ele = (
                    <Col key={i} className="element">
                        {select}
                    </Col>)
            } else if (items[i].type === "multi_select") {
                let val_key = items[i].values;
                let ops = this.state[val_key];
                let opt_ele = [];
                for (let o in ops) {
                    if (ops[o].name !== "全部") {
                        opt_ele.push(<Option key={ops[o].val}>{ops[o].name}</Option>)
                    }
                }
                ele = (
                    <Col key={i} className="element">
                        <Select dropdownMatchSelectWidth={false} dropdownStyle={{ width: "auto" }} key={i} value={this.state[i]} mode="multiple" placeholder={"请选择" + items[i].name} onChange={(val) => { this.selectChange(val, i) }}>
                            {opt_ele}
                        </Select>
                    </Col>
                )
            }
            var divs = (
                <div className="item" key={i}>{label}{ele}</div>
            )
            filter_elements.push(divs);
        }
        return (
            <Row className="filter-bar filter" style={{ display: this.props.hidden ? "none" : "block" }}>
                <Row className="items">
                    {filter_elements}
                    <div className="item">
                        {this.props["data-paths"] ? <Checkbox onChange={this.remeber.bind(this)} checked={this.state.isRember}>记住</Checkbox> : null}
                        <Button type="primary" onClick={() => { this.get_data(false) }} size="small">查询</Button>&emsp;
                        <Button onClick={this.reset_data.bind(this)} size="small">重置</Button>&emsp;
                        {this.props.export ? <Button onClick={() => { this.get_data(true) }} size="small">导出</Button> : null}
                    </div>

                </Row>
                {/* <Row style={{textAlign:"right",marginTop:"10px"}}>
                    <Button type="primary" onClick={this.get_data.bind(this)}>查询</Button>&emsp;
                    <Button onClick={this.reset_data.bind(this)}>重置</Button>
                </Row> */}
            </Row>
        )
    }
}

export default Filter;