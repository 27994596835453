// import { browserHistory } from 'react-router';
import moment from 'moment';
import { page as PAGE } from './config';
// 设置登录状态
export const set_logstate = (iflog,data) => {
	if(iflog){
		localStorage.setItem("isLogin",data);
	}else{
		localStorage.setItem("isLogin","");
	}
    // browserHistory.push(window.location.pathname+window.location.search);
}

// 获取地址栏参数
export const getUrlParam = (name) => {  
    let reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");  
    let r = window.location.search.substr(1).match(reg);  
    if (r!=null) 
      return decodeURI(r[2]); 
    return null;  
}

// 格式化表格参数  添加需要
export const format_table_data = (data) => {
	let source = JSON.parse(JSON.stringify(data));
	for(let s in source){
		var test_key=(parseInt(s,10)+1)<10?"0"+(parseInt(s,10)+1):(parseInt(s,10)+1);
		source[s].key = test_key;
		// source[s] = fmt_obj(source[s]);
		// source[s] = fmt_json(source[s])
	}
	return source;
}

// 格式化日期
export const format_date = (date)=>{
	let format = "YYYY-MM-DD";
	if(typeof date==="object"){
		return date?date.format(format):"";
	}else{
		return date?moment(date).format(format):"";
	}
}
// 格式化日期
export const format_time = (date)=>{
	let format = "YYYY-MM-DD HH:mm:ss";
	if(typeof date==="object"){
		return date?date.format(format):"";
	}else{
		return date?moment(date).format(format):"";
	}
}

// 添加滚动事件
export const add_event = (scrollFunc,ele) => {
	let doc = ele||document;
	if(document.addEventListener){
		doc.addEventListener("DOMMouseScroll",scrollFunc,false);
	}
	doc.onmousewheel = scrollFunc;
}
export const arr_chunk=(array,size)=>{
	//获取数组的长度，如果你传入的不是数组，那么获取到的就是undefined
	const length = array.length
	//判断不是数组，或者size没有设置，size小于1，就返回空数组
	if (!length || !size || size < 1) {
	  return []
	}
	//核心部分
	let index = 0 //用来表示切割元素的范围start
	let resIndex = 0 //用来递增表示输出数组的下标
  
	//根据length和size算出输出数组的长度，并且创建它。
	let result = new Array(2);
	// let result = new Array(Math.ceil(length / size))
	//进行循环
	while (index < length) {
	  //循环过程中设置result[0]和result[1]的值。该值根据array.slice切割得到。
	  result[resIndex++] = array.slice(index, (index += size))
	}
	//输出新数组
	return result
}
export const bmd = {
    transformParams:(params)=>{
        let paramStr = [];
        for(let p in params){
            paramStr.push(p+"="+params[p]);
        }
        paramStr = paramStr.join("&");
        return paramStr;
    },
    // 跳转页面
    // redirect:(url,params=[])=>{
    //     let paramStr = bmd.transformParams(params);
    //     url = url.indexOf("?")>0?url+"&"+paramStr:url+"?"+paramStr
    //     browserHistory.push(url);
    // },
    // 新打开一个页面
    navigate:(url,params=[])=>{
        let paramStr = bmd.transformParams(params);
        url = url.indexOf("?")>0?url+"&"+paramStr:url+"?"+paramStr
        window.open(url)
    },
    // 没有显示--
    show_blank:(data)=>{
        return data||"--"
    },
    // 退出登录
    // logout:()=>{
    //     bmd.navigate(logout_page+"?redirectUrl="+window.location.href);
    // },
    //修改密码
    // password:()=>{
    //     bmd.navigate(pwd_page+"?redirectUrl="+window.location.href);
    // },
    // percent:(num)=>{
    //     num = parseFloat(num);
    //     if(isNaN(num)){
    //         return "--"
    //     }
    //     return Decimal.mul(num,100).toString()+"%";
    // },
    money:function(money){
        if(money===undefined||money===null){
            return "--"
        }
        if(!money){
            return "0.00"
        }
        let num_str = parseFloat(money/100).toFixed(2);
        // let num_int = parseInt(num_str.split(".")[0],10);
        let num_int = parseInt(num_str.split(".")[0],10).toLocaleString();
        return num_int + "." + num_str.split(".")[1];
    },
    moneyNum(money){
        if(!money){
            return "0.00"
        }
        return parseFloat(money/100).toFixed(2);
    },
    remoney:function(moneyStr){
        if(!moneyStr){
            return "--"
        }
        let money = parseFloat(moneyStr)*100;
        return parseInt(money.toFixed(0),10)||0;
    },
    resolveBlank(data){
        return data===null?"--":(data===undefined?"---":data);
    },
    unfoldObjArray(arr){
        let res = [];
        for(let a in arr){
            let detail = arr[a],obj={},temp = [];
            if(detail===null||detail===undefined){
                continue;
            }
            for(let d in detail){
                if(Array.isArray(detail[d])){
                    temp = detail[d];
                }else{
                    obj[d] = detail[d];
                }
            }
            for(let t in temp){
                obj.akid = a+"-"+t;
                res.push(JSON.parse(JSON.stringify(Object.assign(obj,temp[t]))));
            }
        }
        return res;
    },
    getSelect(axios,select_data){
        axios.get(select_data,null).then((data)=>{
            localStorage.setItem("select",JSON.stringify(data.data||{}));
        })
    },
    //表格排序
    getSort(a,b,str,time){
        if(a.key==="合计"||b.key==="合计"){
            return;
        }
        // alert(1)
        if(time){
            return (a[str]?this.getTimes(a[str]):0) - (b[str]?this.getTimes(b[str]):0)
        }else{
            return (a[str]?Number(a[str]):0) - (b[str]?Number(b[str]):0)
        }
    },
    getTimes(str){
        var date = str;
        date = date.substring(0,19);    
        date = date.replace(/-/g,'/'); //必须把日期'-'转为'/'
        var timestamp = new Date(date).getTime();
        return timestamp;
    },
    getPagination(page=0){
        return { start:page*PAGE.size-PAGE.size, size:PAGE.size*10000  }
    },
    formatMMDD(date){
        return moment(date).format("MM-DD");
    },
    formatHHMM(date){
        return moment(date).format("MM-DD HH:mm");
    },
    formatDate(date){
        if(typeof date === 'object') return date.format("YYYY-MM-DD");
        else return moment(date).format("YYYY-MM-DD");
    },
    formatTime(date,start){
        if(typeof date === 'object') return start?date.format("YYYY-MM-DD 00:00:00"):date.format("YYYY-MM-DD 23:59:59");
        else return start?moment(date).format("YYYY-MM-DD 00:00:00"):moment(date).format("YYYY-MM-DD 23:59:59");
    },
    formatMinute(date,start){
        if(typeof date === 'object') return start?date.format("YYYY-MM-DD 00:00:00"):date.format("YYYY-MM-DD HH:mm:00");
        else return start?moment(date).format("YYYY-MM-DD 00:00:00"):moment(date).format("YYYY-MM-DD HH:mm:00");
    },
    formatMonth(date){
        if(typeof date === 'object') return date.format("YYYY-MM")+"-01";
        else return moment(date).format("YYYY-MM")+"-01";
    }
    // myBrowser() {
    //     var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    //     var isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
    //     var isIE = userAgent.indexOf("compatible") > -1
    //             && userAgent.indexOf("MSIE") > -1 && !isOpera; //判断是否IE浏览器
    //     var isEdge = userAgent.indexOf("Edge") > -1; //判断是否IE的Edge浏览器
    //     var isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
    //     var isSafari = userAgent.indexOf("Safari") > -1
    //             && userAgent.indexOf("Chrome") == -1; //判断是否Safari浏览器
    //     var isChrome = userAgent.indexOf("Chrome") > -1
    //             && userAgent.indexOf("Safari") > -1; //判断Chrome浏览器
    
    //     if (isIE) {
    //         var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
    //         reIE.test(userAgent);
    //         var fIEVersion = parseFloat(RegExp["$1"]);
    //         if (fIEVersion == 7) {
    //             return "IE7";
    //         } else if (fIEVersion == 8) {
    //             return "IE8";
    //         } else if (fIEVersion == 9) {
    //             return "IE9";
    //         } else if (fIEVersion == 10) {
    //             return "IE10";
    //         } else if (fIEVersion == 11) {
    //             return "IE11";
    //         } else {
    //             return "0";
    //         }//IE版本过低
    //         return "IE";
    //     }
    //     if (isOpera) {
    //         return "Opera";
    //     }
    //     if (isEdge) {
    //         return "Edge";
    //     }
    //     if (isFF) {
    //         return "FF";
    //     }
    //     if (isSafari) {
    //         return "Safari";
    //     }
    //     if (isChrome) {
    //         return "Chrome";
    //     }
        
    // }
}
//乘法 小数精度丢失
export const accMul=(arg1, arg2)=>{
    if(arg1===undefined||arg1===null){
        return;
    }
	var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
	try { m += s1.split(".")[1].length } catch (e) { }
	try { m += s2.split(".")[1].length } catch (e) { }
	return (Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)).toFixed(3)
}
//除法 小数精度丢失
export const accDiv=(arg1,arg2)=>{
    if(arg1===undefined||arg1===null){
        return;
    }
    var t1=0,t2=0,r1,r2; 
 try{t1=arg1.toString().split(".")[1].length}catch(e){} 
 try{t2=arg2.toString().split(".")[1].length}catch(e){} 
 if(Math){ 
 r1=Number(arg1.toString().replace(".","")) 
 r2=Number(arg2.toString().replace(".","")) 
 return accMul((r1/r2),Math.pow(10,t2-t1)); 
 } 
 } 

//数组对象方法排序:升序
export const sortByKey=(array, key) =>{
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }
  //数组对象方法排序:降序
  export  const sortDownByKey=(array, key)=> {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return ((x > y) ? -1 : ((x < y) ? 1 : 0));
    });
  }

