import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu } from 'antd';
const SubMenu = Menu.SubMenu;
class SiderCustom extends Component {
    constructor(props) {
        super(props);
        let keys = document.location.pathname
        let openKeys = keys.split("/").splice(0, 2);
        keys = keys.split("/").splice(0, 4);
        this.state = {
            collapsed: true,
            selectKeys: [keys.join('/')],
            openKeys: [openKeys.join("/")]
        };
    }
    onOpenChange(openKeys) {
        if (openKeys.length > 1) {
            openKeys.shift();
        }
        this.setState({
            openKeys
        });
    }
    onSelect(data) {
        let keys = data.key.split("/");
        let openKeys = keys.splice(0, 2);
        this.setState({
            openKeys: [openKeys.join("/")]
        })
    }
    render() {
        let keys = document.location.pathname;
        keys = keys.split("/").splice(0, 3).join("/");
        // var heights=window.innerHeight-170;
        return (
            <Menu defaultSelectedKeys={[keys]} defaultOpenKeys={this.state.openKeys} onOpenChange={this.onOpenChange.bind(this)} openKeys={this.state.openKeys} onSelect={this.onSelect.bind(this)} mode="inline" selectedKeys={[keys]} style={{ marginTop: "10px", overflowY: "auto", overflowX: "hidden" }} theme="dark">
                <SubMenu title="避雷针系统" key="/blz">
                    <Menu.Item key={"/blz/source"}>
                        <Link to="/blz/source"><span>数据源统计</span></Link>
                    </Menu.Item>
                    <Menu.Item key={"/blz/source_supplier"}>
                        <Link to="/blz/source_supplier"><span>数据源厂商统计</span></Link>
                    </Menu.Item>
                    <Menu.Item key={"/blz/product"}>
                        <Link to="/blz/product"><span>数据产品统计</span></Link>
                    </Menu.Item>
                    <Menu.Item key={"/blz/finance_total"}><Link to="/blz/finance_total">财务统计</Link></Menu.Item>
                    <Menu.Item key={"/blz/finance_sort"}><Link to="/blz/finance_sort">财务统计排序</Link></Menu.Item>
                    <Menu.Item key={"/blz/finance_list"}><Link to="/blz/finance_list">财务列表统计</Link></Menu.Item>
                    <Menu.Item key={"/blz/customer_group_multi"}><Link to="/blz/customer_group_multi">客群多头统计</Link></Menu.Item>
                </SubMenu>
                <SubMenu title="朴道系统" key="/pudao">
                    <Menu.Item key={"/pudao/source"}>
                        <Link to="/pudao/source"><span>数据源统计</span></Link>
                    </Menu.Item>
                    <Menu.Item key={"/pudao/source_supplier"}>
                        <Link to="/pudao/source_supplier"><span>数据源厂商统计</span></Link>
                    </Menu.Item>
                    <Menu.Item key={"/pudao/product"}>
                        <Link to="/pudao/product"><span>数据产品统计</span></Link>
                    </Menu.Item>
                    <Menu.Item key={"/pudao/finance_total"}><Link to="/pudao/finance_total">财务统计</Link></Menu.Item>
                    <Menu.Item key={"/pudao/finance_sort"}><Link to="/pudao/finance_sort">财务统计排序</Link></Menu.Item>
                    <Menu.Item key={"/pudao/finance_list"}><Link to="/pudao/finance_list">财务列表统计</Link></Menu.Item>
                </SubMenu>
            </Menu>
        )
    }
}

export default withRouter(SiderCustom);