import React,{Component} from 'react';
import Column from '../../../templates/chartColumn';
import Line from '../../../templates/chartLine';
class DemoColumn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
    this.chartArr=[
      {
        yField:"totalCount",
        title:"总条数"
      },
      {
        yField:"chargedCount",
        title:"计费条数"
      },
      {
        yField:"revenue",
        title:"原始收入"
      },
      {
        yField:"cost",
        title:"原始成本"
      },
      {
        yField:"profit",
        title:"原始毛利"
      },
      {
        yField:"profitRate",
        title:"原始毛利率",
        rate:true
      },
      {
        yField:"pudaoProfit",
        title:"朴道分润"
      },
      {
        yField:"blzProfit",
        title:"避雷针利润"
      },
      {
        yField:"blzProfitRate",
        title:"避雷针毛利率",
        rate:true
      },
      {
        yField:"pudaoPayableCost",
        title:"朴道应付自有数据源成本"
      },
      {
        yField:"pudaoBlzDataSourceCost",
        title:"自有数据源真实成本",
      },
    ]
}
  componentWillMount(){

  }
  
  render(){
    var data=this.props.data;
    var Modals=this.props.type === "column" ?Column:Line
    return <div style={{ overflow: "hidden" }}>
      {
        this.chartArr.map((i,k)=>{
          return <Modals data={data} xField="statsTime" yField={i.yField}  seriesField={this.props.dimension==="NONE"?null:"dimensionName"} title={i.title} rate={i.rate} loading={this.props.loading} />
        })
      }
    </div>;
  }
};

export default DemoColumn;
