import React, {Component} from 'react';
import Filter from '../../templates/Filter_obj8';
import {Radio, Spin, Table} from 'antd';
import Chart from './chart';
import {axios_f} from '../../../ajax/request';
import {dropdown_datasource_code, dropdown_datasource_time, get_source_supplier_list} from '../../../ajax/api';
import {page} from '../../../ajax/config';
import moment from 'moment';

class ApplyTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chart:"column",
            type:"table",
            pageSize: page.size,
            data: [],
            list: [],
            listPage: 0,
            current: 1,
            filter:{
                tg:"DAY",
                // beginDate:moment().subtract(1,).format("YYYY-MM-01"),endDate:moment().format("YYYY-MM-01"),
                beginDate:moment().startOf("month").format("YYYY-MM-DD"),endDate:moment().format("YYYY-MM-DD")
            }
        };
    }
    componentWillMount() {
        this.get_select();
        this.get_list(1,this.state.filter);
        this.filter = {
            tg: {
                name: "时间维度",
                type: "select",
                allHide:true,
                values:"timeType",
                resetValue: "DAY"
            },
            time:{
                name:"时间",
                type:"time",
                default:[moment().startOf("month"),moment()]
            },
            dataSourceSupplierId: {
                name: "数据源厂商",
                type: "select",
                values:"datasourceSupplier"
            },
        }
        this.columns=[
            {
                title:"序号",
                // dataIndex:"",
                render:(data,e,index)=>{
                    return index+1
                }
            },
            {
                title:"时间",
                dataIndex:"date",
                render:e=>e.replace(/_/g,"-")
            },
            {
                title:"数据源厂商",
                dataIndex:"dataSourceSupplierName",
            },
            {
                title:"总条数",
                dataIndex:"totalCount",
            },
            {
                title:"成功条数",
                dataIndex:"successCount",
                
            },
            {
                title:"付费条数",
                dataIndex:"payCount",
                
            },
            {
                title:"付费率",
                dataIndex:"payRate",
                render:(data)=>{
                    return data+"%"
                }
            },
            {
                title:"失败率",
                dataIndex:"failRate",
                render:(data)=>{
                    return data+"%"
                }
            },
        ]
    }
    get_list(page_no = 1, filter = {},type=this.state.type){
        let rqd = JSON.parse(JSON.stringify(filter)),param={};
        rqd.statsEnv = 'BLZ';
        param={...rqd}
        // let type=type||this.state.type
        if(type==="table"){
            param.page = page_no;
            param.size = page.size;
        }
        this.setState({
            spinning:true
        })
        // param.filter = JSON.stringify(rqd);
        axios_f.post(get_source_supplier_list,param).then(e=>{
            if(!e.code){
                var data=e.data.list;
                for(var i in data){
                    for(var j in data[i]){
                        data[i]["date"] = data[i]["date"].replace(/-/g, "_")
                      if(j.indexOf("Rate")!==-1){
                        data[i][j]=Number(Number((data[i][j]*100)).toFixed(3))
                      }
                    }
                  }
                this.setState({
                    data:data,
                    current:e.data.page||1,
                    pageTotal:e.data.total,
                    spinning:false
                })
            }
        }).catch(e=>{
            this.setState({
                data:[],
                spinning:false
            })
        })
      }
    get_select(){
        axios_f.get(dropdown_datasource_code).then(e=>{
            if(!e.code){
                this.setState({
                    datasourceSupplier:e.data.supplier
                })
            }
        })
        axios_f.get(dropdown_datasource_time).then(e=>{
            if(!e.code){
                this.setState({
                    timeType:e.data.temporalGranularity
                })
            }
        })
        // axios_f.get(dropdown_datasource_type).then(e=>{
        //     if(!e.code){
        //         this.setState({
        //             datasourceType:e.data
        //         })
        //     }
        // })
    }
    get_filter(data) {
        this.setState({
            filter: data,
            current:1
        })
        this.get_list(1,data);
    }
    typeChange(e){
        this.setState({
            type:e.target.value
        })
        this.get_list(this.state.current,this.state.filter,e.target.value);
    }
    chartChange(e){
        this.setState({
            chart:e.target.value
        })
    }
    //翻页
    page_up(page){
        window.scrollTo(0, 0);
        this.setState({
            current: page,
        })
        this.get_list(page,this.state.filter,this.state.type);
    }
    render() {
        const filter={
            "timeType":this.state.timeType,
            "data-source":this.filter,
            "data-get":this.get_filter.bind(this),
            "datasourceSupplier":this.state.datasourceSupplier,
            tg:"DAY",
            time:[moment().startOf("month"),moment()]
        }
        const pagination={
            total: this.state.pageTotal,
            current: this.state.current,
            onChange: this.page_up.bind(this),
            pageSize:this.state.pageSize,
            showSizeChanger:false
        }
        const table={
            dataSource:this.state.data,
            columns:this.columns,
            pagination:pagination,
            bordered:true,
        }
        
        return (
            <div>
                <Filter {...filter} />
                <div className="Component-body" >
                    <span style={{ display: "inline-block", width: "100%", textAlign: "right",marginBottom:"10px" }}>
                    {this.state.type==="chart"?
                        <Radio.Group buttonStyle="solid" size="small" style={{ marginRight: "10px" }} onChange={this.chartChange.bind(this)} value={this.state.chart}>
                            <Radio.Button value="column">柱状图</Radio.Button>
                            <Radio.Button value="line">折线图</Radio.Button>
                        </Radio.Group>
                    :null}
                        <Radio.Group buttonStyle="solid" size="small" style={{ marginRight: "0" }} onChange={this.typeChange.bind(this)} value={this.state.type}>
                            <Radio.Button value="table">表格</Radio.Button>
                            <Radio.Button value="chart">图表</Radio.Button>
                        </Radio.Group>
                    </span>
                    <Spin spinning={this.state.spinning}>
                    {this.state.type==="chart"?<Chart type={this.state.chart} data={this.state.data} loading={this.state.spinning} />:<Table {...table} />}
                    </Spin>
                    
                </div>
            </div>
        )
    }
}

export default ApplyTab;