import React,{Component} from 'react';
import Line from '../../templates/chartLine';
class DemoColumn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
    this.appNumHitRateChartArr=[
      {
        yField:"blz3dAllAllAppNumHitRate",
        title:"3天内所有机构全时段的申请数命中率"
      }, 
      {
          yField:"blz7dAllAllAppNumHitRate",
          title:"7天内所有机构全时段的申请数命中率"
      },     
      {
          yField:"blz15dAllAllAppNumHitRate",
          title:"15天内所有机构全时段的申请数命中率"
      },      
      {
          yField:"blz1mAllAllAppNumHitRate",
          title:"1月内所有机构全时段的申请数命中率"
      },
      {
          yField:"blz3mAllAllAppNumHitRate",
          title:"3月内所有机构全时段的申请数命中率"
      }, 
      {
          yField:"blz6mAllAllAppNumHitRate",
          title:"6月内所有机构全时段的申请数命中率"
      },   
      {
          yField:"blz9mAllAllAppNumHitRate",
          title:"9月内所有机构全时段的申请数命中率"
      },   
      {
          yField:"blz12mAllAllAppNumHitRate",
          title:"12月内所有机构全时段的申请数命中率"
      },
    ];
    this.orgDisnumHitRateChartArr=[
      {
          yField:"blz3dAllAllOrgDisnumHitRate",
          title:"3天内所有机构全时段的不同申请机构数命中率"
      },
      {
          yField:"blz7dAllAllOrgDisnumHitRate",
          title:"7天内所有机构全时段的不同申请机构数命中率"
      },
      {
          yField:"blz15dAllAllOrgDisnumHitRate",
          title:"15天内所有机构全时段的不同申请机构数命中率"
      },
      {
          yField:"blz1mAllAllOrgDisnumHitRate",
          title:"1月内所有机构全时段的不同申请机构数命中率"
      },
      {
          yField:"blz3mAllAllOrgDisnumHitRate",
          title:"3月内所有机构全时段的不同申请机构数命中率"
      },
      {
          yField:"blz6mAllAllOrgDisnumHitRate",
          title:"6月内所有机构全时段的不同申请机构数命中率"
      },
      {
          yField:"blz9mAllAllOrgDisnumHitRate",
          title:"9月内所有机构全时段的不同申请机构数命中率"
      },
      {
          yField:"blz12mAllAllOrgDisnumHitRate",
          title:"12月内所有机构全时段的不同申请机构数命中率"
      },
    ];
    this.appNumAvgChartArr=[
      {
          yField:"blz3dAllAllAppNumAvg",
          title:"3天内所有机构全时段的申请数均值"
      },
      {
          yField:"blz7dAllAllAppNumAvg",
          title:"7天内所有机构全时段的申请数均值"
      },
      {
          yField:"blz15dAllAllAppNumAvg",
          title:"15天内所有机构全时段的申请数均值"
      },
      {
          yField:"blz1mAllAllAppNumAvg",
          title:"1月内所有机构全时段的申请数均值"
      },
      {
          yField:"blz3mAllAllAppNumAvg",
          title:"3月内所有机构全时段的申请数均值"
      },
      {
          yField:"blz6mAllAllAppNumAvg",
          title:"6月内所有机构全时段的申请数均值"
      },
      {
          yField:"blz9mAllAllAppNumAvg",
          title:"9月内所有机构全时段的申请数均值"
      },
      {
          yField:"blz12mAllAllAppNumAvg",
          title:"12月内所有机构全时段的申请数均值"
      },
    ];
    this.orgDisnumAvgChartArr=[
      {
          yField:"blz3dAllAllOrgDisnumAvg",
          title:"3天内所有机构全时段的不同申请机构数均值"
      },
      {
          yField:"blz7dAllAllOrgDisnumAvg",
          title:"7天内所有机构全时段的不同申请机构数均值"
      },
      {
          yField:"blz15dAllAllOrgDisnumAvg",
          title:"15天内所有机构全时段的不同申请机构数均值"
      },
      {
          yField:"blz1mAllAllOrgDisnumAvg",
          title:"1月内所有机构全时段的不同申请机构数均值"
      },
      {
          yField:"blz3mAllAllOrgDisnumAvg",
          title:"3月内所有机构全时段的不同申请机构数均值"
      },
      {
          yField:"blz6mAllAllOrgDisnumAvg",
          title:"6月内所有机构全时段的不同申请机构数均值"
      },
      {
          yField:"blz9mAllAllOrgDisnumAvg",
          title:"9月内所有机构全时段的不同申请机构数均值"
      },
      {
          yField:"blz12mAllAllOrgDisnumAvg",
          title:"12月内所有机构全时段的不同申请机构数均值"
      },
    ];
  }
  componentWillMount(){

  }
  render(){
    var arr=this.props.data;
    console.log(arr)
    var map = {},
      dest = [];
      for (var i = 0; i < arr.length; i++) {
        var ai = arr[i];
        if (!map[ai.customerGroupType]) {
          dest.push({
            customerGroupType: ai.customerGroupType,
            data:[ai]
          });
          map[ai.customerGroupType] = ai;
        } else {
          for (var j = 0; j < dest.length; j++) {
            var dj = dest[j];
            if (dj.customerGroupType === ai.customerGroupType) {
              dj.data.push(ai);
              break;
            }
          }
        }
      }
    
    var chartArr;
    switch(this.props.type){
      case "appNumHitRate":{
        chartArr = this.appNumHitRateChartArr
        break
      }
      case "orgDisnumHitRate":{
        chartArr = this.orgDisnumHitRateChartArr
        break
      }
      case "appNumAvg":{
        chartArr = this.appNumAvgChartArr
        break
      }
      case "orgDisnumAvg":{
        chartArr = this.orgDisnumAvgChartArr
        break
      }
      default:{
        chartArr = []
      }
    }
    return <div style={{ overflow: "hidden" }}>
      {
        chartArr.map((i,k)=>{
          return <Line data={dest} xField="statDate" yField={i.yField} seriesField={"customerGroupType"} title={i.title} rate={i.yField.indexOf("Rate") !== -1} loading={this.props.loading} search />
        })
      }
    </div>;
  }
};
export default DemoColumn;
