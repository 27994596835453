import React, { Component } from 'react';
import Filter from '../../templates/Filter_obj8';
import { Radio, Spin } from 'antd';
import Chart from './chart';
import { axios_monitor } from '../../../ajax/request';
import { get_customer_group_multi_list } from '../../../ajax/api';
import moment from 'moment';
class ApplyTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chart: "appNumHitRate",
            data: [
            ],
            list: [],
            filter: {
                beginDate: moment().startOf("year").format("YYYY-MM-01"), endDate: moment().format("YYYY-MM-01"),
            }
        };
    }
    componentWillMount() {
        this.get_list(this.state.filter);
        this.filter = {
            time: {
                name: "时间",
                type: "time",
                default: [moment().startOf("month"), moment()]
            },
        }
        this.columns = [
            {
                title: "序号",
                render: (data, e, index) => {
                    return index + 1
                }
            },
            {
                title: "时间",
                dataIndex: "statDate",
                render: e => e.replace(/_/g, "-")
            },
            {
                title: "客群类型",
                dataIndex: "customerGroupType",
                className: "columnHide"
            },
            { title: "3天内所有机构全时段的申请数命中率", dataIndex: "blz3dAllAllAppNumHitRate", render: (data) => { return data + "%" } },
            { title: "3天内所有机构全时段的申请数均值", dataIndex: "blz3dAllAllAppNumAvg" },
            { title: "7天内所有机构全时段的申请数命中率", dataIndex: "blz7dAllAllAppNumHitRate", render: (data) => { return data + "%" } },
            { title: "7天内所有机构全时段的申请数均值", dataIndex: "blz7dAllAllAppNumAvg" },
            { title: "15天内所有机构全时段的申请数命中率", dataIndex: "blz15dAllAllAppNumHitRate", render: (data) => { return data + "%" } },
            { title: "15天内所有机构全时段的申请数均值", dataIndex: "blz15dAllAllAppNumAvg" },
            { title: "1月内所有机构全时段的申请数命中率", dataIndex: "blz1mAllAllAppNumHitRate", render: (data) => { return data + "%" } },
            { title: "1月内所有机构全时段的申请数均值", dataIndex: "blz1mAllAllAppNumAvg" },
            { title: "3月内所有机构全时段的申请数命中率", dataIndex: "blz3mAllAllAppNumHitRate", render: (data) => { return data + "%" } },
            { title: "3月内所有机构全时段的申请数均值", dataIndex: "blz3mAllAllAppNumAvg" },
            { title: "6月内所有机构全时段的申请数命中率", dataIndex: "blz6mAllAllAppNumHitRate", render: (data) => { return data + "%" } },
            { title: "6月内所有机构全时段的申请数均值", dataIndex: "blz6mAllAllAppNumAvg" },
            { title: "9月内所有机构全时段的申请数命中率", dataIndex: "blz9mAllAllAppNumHitRate", render: (data) => { return data + "%" } },
            { title: "9月内所有机构全时段的申请数均值", dataIndex: "blz9mAllAllAppNumAvg" },
            { title: "12月内所有机构全时段的申请数命中率", dataIndex: "blz12mAllAllAppNumHitRate", render: (data) => { return data + "%" } },
            { title: "12月内所有机构全时段的申请数均值", dataIndex: "blz12mAllAllAppNumAvg" },
            { title: "3天内所有机构全时段的不同申请机构数命中率", dataIndex: "blz3dAllAllOrgDisnumHitRate", render: (data) => { return data + "%" } },
            { title: "3天内所有机构全时段的不同申请机构数均值", dataIndex: "blz3dAllAllOrgDisnumAvg" },
            { title: "7天内所有机构全时段的不同申请机构数命中率", dataIndex: "blz7dAllAllOrgDisnumHitRate", render: (data) => { return data + "%" } },
            { title: "7天内所有机构全时段的不同申请机构数均值", dataIndex: "blz7dAllAllOrgDisnumAvg" },
            { title: "15天内所有机构全时段的不同申请机构数命中率", dataIndex: "blz15dAllAllOrgDisnumHitRate", render: (data) => { return data + "%" } },
            { title: "15天内所有机构全时段的不同申请机构数均值", dataIndex: "blz15dAllAllOrgDisnumAvg" },
            { title: "1月内所有机构全时段的不同申请机构数命中率", dataIndex: "blz1mAllAllOrgDisnumHitRate", render: (data) => { return data + "%" } },
            { title: "1月内所有机构全时段的不同申请机构数均值", dataIndex: "blz1mAllAllOrgDisnumAvg" },
            { title: "3月内所有机构全时段的不同申请机构数命中率", dataIndex: "blz3mAllAllOrgDisnumHitRate", render: (data) => { return data + "%" } },
            { title: "3月内所有机构全时段的不同申请机构数均值", dataIndex: "blz3mAllAllOrgDisnumAvg" },
            { title: "6月内所有机构全时段的不同申请机构数命中率", dataIndex: "blz6mAllAllOrgDisnumHitRate", render: (data) => { return data + "%" } },
            { title: "6月内所有机构全时段的不同申请机构数均值", dataIndex: "blz6mAllAllOrgDisnumAvg" },
            { title: "9月内所有机构全时段的不同申请机构数命中率", dataIndex: "blz9mAllAllOrgDisnumHitRate", render: (data) => { return data + "%" } },
            { title: "9月内所有机构全时段的不同申请机构数均值", dataIndex: "blz9mAllAllOrgDisnumAvg" },
            { title: "12月内所有机构全时段的不同申请机构数命中率", dataIndex: "blz12mAllAllOrgDisnumHitRate", render: (data) => { return data + "%" } },
            { title: "12月内所有机构全时段的不同申请机构数均值", dataIndex: "blz12mAllAllOrgDisnumAvg" },
        ]
    }
    get_list(filter = {}) {
        let rqd = JSON.parse(JSON.stringify(filter)), param = {};
        param = { ...rqd }
        this.setState({
            spinning: true
        })
        axios_monitor.post(get_customer_group_multi_list, param).then(e => {
            if (!e.code) {
                var data = e.data;
                for (var i in data) {
                    data[i]["statDate"] = data[i]["statDate"].substring(0, 7).replace(/-/g, "_")
                    data[i]["customerGroupType"] = data[i]["customerGroupType"] + "客群"
                    for (var j in data[i]) {
                        if (j.indexOf("Rate") !== -1) {
                            data[i][j] = Number(Number((data[i][j] * 100)).toFixed(2))
                        }
                        if (j.indexOf("Avg") !== -1) {
                            data[i][j] = Number(Number((data[i][j])).toFixed(3))
                        }
                    }
                }
                this.setState({
                    data: data,
                    spinning: false
                })
            }
        }).catch(e => {
            this.setState({
                data: [],
                spinning: false
            })
        })
    }
    get_filter(data) {
        this.setState({
            filter: data
        })
        this.get_list(data);
    }
    chartChange(e) {
        this.setState({
            chart: e.target.value
        })
    }
    render() {
        const filter = {
            "data-source": this.filter,
            "data-get": this.get_filter.bind(this),
            timeGranularity: "MONTH",
            time: [moment().startOf("year"), moment()],
        }

        return (
            <div>
                <Filter {...filter} />
                <div className="Component-body" >
                    <span style={{ display: "inline-block", width: "100%", textAlign: "right", marginBottom: "10px" }}>
                        <Radio.Group buttonStyle="solid" size="small" onChange={this.chartChange.bind(this)} value={this.state.chart}>
                            <Radio.Button value="appNumHitRate">申请数命中率</Radio.Button>
                            <Radio.Button value="orgDisnumHitRate">申请机构数(去重)命中率</Radio.Button>
                            <Radio.Button value="appNumAvg">申请数均值</Radio.Button>
                            <Radio.Button value="orgDisnumAvg">申请机构数(去重)均值</Radio.Button>
                        </Radio.Group>
                    </span>
                    <Spin spinning={this.state.spinning}>
                        <Chart type={this.state.chart} data={this.state.data} loading={this.state.spinning} />
                    </Spin>
                </div>
            </div>
        )
    }
}

export default ApplyTab;