/**
 * Created by 叶子 on 2017/7/30.
 * http通用工具函数
 */
import ax from 'axios';
import { message } from 'antd';
import { host,monitor_host,login_page } from './config';

const error_info = {
    net: "网络异常",
    404: "请求地址错误",
    500: "服务器异常",
    401: "未登录",
    302: "未登录",
    403: "认证失败",
    502: "服务器正在部署",
    405: "请求方法错误"
}

// 数据请求参数
const axios_config_new = {
    timeout: 120000,
    responseType: 'json',
    withCredentials: true,
    validateStatus: function(status) {
        return (status >= 200 && status < 300);
    },
    transformRequest: [function(data) {
        if(typeof(data)==="string"){
            return data;
        }
        let ret = [];
        for (let it in data) {
            if(typeof data[it] === 'object') data[it] = JSON.stringify(data[it]);
            ret.push(it + "=" + encodeURIComponent(data[it]));
        }
        return ret.join("&");
    }],
    transformResponse: [function(data) {
        let res;
        try {
            res = JSON.parse(JSON.stringify(data));
            // 100 未登录 200 权限不足 300 权限不足 0 未定义错误
            if(res.code===100){
                window.location.href = login_page+"?redirectUrl="+window.location.href;
                message.warn("未登录！");
                // return Promise.reject(res.msg,duration);
            }
            // if(res.errMsg){
            //     message.error(res.errMsg);
            //     return Promise.resolve(res)
            // }
            if(res.code!==0){
                message.error(res.detail||res.msg);
                // message.error("错误代码："+res.code+"--错误信息："+res.msg, duration);
                return Promise.reject(res.msg)
            }
        } catch (e) {
            message.error("返回值格式错误");
            res = { state: -100, msg: "HAHA", data: null };
        }
        return res;
    }]
}
// 拦截器处理
const interceptors_res = (response) => {
    
    return response.data;
}
const interceptors_ers = (err) => {
    let status = err.response ? err.response.status : "net";
    message.error(error_info[status], 3);
    if(status==='net'){
    }
    return Promise.reject(error_info[status])
}

const axiosa = ax.create(axios_config_new);
axiosa.defaults.baseURL = host;
axiosa.interceptors.response.use(interceptors_res, interceptors_ers);
// export default axios;
export const axios_f=axiosa

const axiosm = ax.create(axios_config_new);
axiosm.defaults.baseURL = monitor_host;
axiosm.interceptors.response.use(interceptors_res, interceptors_ers);
// export default axios;
export const axios_monitor=axiosm
