import React, { Component } from 'react';
import Filter from '../../../templates/Filter_obj8';
import { Radio, Spin } from 'antd';
import Chart from './chart';
import { axios_f } from '../../../../ajax/request';
import { dropdown_datasource_time, get_finance_list, dropdown_datasource_code, get_finance_download } from '../../../../ajax/api';
import { page, host } from '../../../../ajax/config';
import moment from 'moment';
import {accDiv} from '../../../../ajax/tool';
class ApplyTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chart: "column",
            type: "table",
            pageSize: page.size,
            data: [
                {
                    chargedCount: 0,
                    cost: 0,
                    profit: 0,
                    profitRate: 0,
                    revenue: 0,
                    statsTime: "2020-1",
                    totalCount: 0,
                }
            ],
            list: [],
            listPage: 0,
            current: 1,
            filter: {
                timeGranularity: "MONTH",
                beginDate: moment().startOf("year").format("YYYY-MM-01"), endDate: moment().format("YYYY-MM-01"),
                dimension: "NONE"
            }
        };
    }
    componentWillMount() {
        this.get_select();
        this.get_list(1, this.state.filter);
        this.filter = {
            timeGranularity: {
                name: "时间维度",
                type: "select",
                allHide: true,
                values: "timeType",
                resetValue: "MONTH"
            },
            time: {
                name: "时间",
                type: "time",
                default: [moment().startOf("year"), moment()]
            },
            productId: {
                name: "产品名称",
                type: "select",
                values: "product"
            },
            customerId: {
                name: "客户名称",
                type: "select",
                values: "customer"
            },
            managerId: {
                name: "客户经理",
                type: "select",
                values: "manager"
            },
            dimension: {
                name: "数据聚合",
                type: "select",
                values: "thirdType",
                allHide: true,
                resetValue: "NONE"
            }
        }
        this.columns = [
            {
                title: "序号",
                // dataIndex:"",
                render: (data, e, index) => {
                    return index + 1
                }
            },
            {
                title: "时间",
                dataIndex: "statsTime",
            },
            {
                title: this.state.thirdName,
                dataIndex: "dimensionName",
                className: "columnHide",
                render: e => e || "--"
            },
            {
                title: "总条数",
                dataIndex: "totalCount",
            },
            {
                title: "计费条数",
                dataIndex: "chargedCount",

            },
            {
                title: "收入",
                dataIndex: "revenue",

            },
            {
                title: "成本",
                dataIndex: "cost",

            },
            {
                title: "毛利",
                dataIndex: "profit",

            },
            {
                title: "毛利率",
                dataIndex: "profitRate",
                render: (data) => {
                    return data + "%"
                }
            }
        ]
    }
    get_list(page_no = 1, filter = {}, type = this.state.type) {
        let rqd = JSON.parse(JSON.stringify(filter)), param = {};
        rqd.statsEnv = 'BLZ';
        param = { ...rqd }
        this.setState({
            spinning: true
        })
        axios_f.post(get_finance_list, param).then(e => {
            if (!e.code) {
                var data = e.data.list;
                for (var i in data) {
                    for (var j in data[i]) {
                        data[i]["statsTime"] = data[i]["statsTime"].replace(/-/g, "_")
                        if (j.indexOf("Rate") !== -1) {
                            data[i][j] = Number((data[i][j] * 100).toFixed(3))
                        }else{
                            if(j==="revenue"||j==="cost"||j==="profit"){
                                data[i][j]=Number(accDiv(data[i][j],1000))
                            }
                        }
                    }
                }
                this.setState({
                    data: data,
                    current: e.data.page || 1,
                    pageTotal: e.data.total,
                    spinning: false
                })
            }
        }).catch(e => {
            this.setState({
                data: [],
                spinning: false
            })
        })
    }
    get_select() {
        axios_f.get(dropdown_datasource_code).then(e => {
            if (!e.code) {
                
                this.setState({
                    manager: e.data.manager,
                    product:e.data.product,
                    customer:e.data.customer
                })
            }
        })
        // axios_f.get(dropdown_financial_third).then(e => {
        //     if (!e.code) {
                
        //         this.setState({
        //             thirdType:value
        //         })
        //     }
        // })
        axios_f.get(dropdown_datasource_time).then(e => {
            if (!e.code) {
                var thirdType=e.data.dimension,value=[];
                thirdType.forEach(i=>{
                    if(i.value!=="PRODUCT_APP"){
                        value.push(i);
                    }
                })
                this.setState({
                    timeType: e.data.timeGranularity,
                    thirdType:value
                })
            }
        })
        // axios_f.get(dropdown_product).then(e => {
        //     if (!e.code) {
        //         this.setState({
        //             product: e.data
        //         })
        //     }
        // })
        // axios_f.get(dropdown_customer).then(e => {
        //     if (!e.code) {
        //         this.setState({
        //             customer: e.data
        //         })
        //     }
        // })
    }
    get_filter(data) {
        this.state.thirdType.forEach(item => {
            this.columns.forEach(col => {
                if (col.dataIndex === "dimensionName" && item.val === data.dimension) {
                    if (data.dimension === "NONE") {
                        col.className = "columnHide"
                    } else {
                        col.title = item.name
                        col.className = ""
                    }
                }
            })
        })
        this.setState({
            filter: data,
            current: 1
        })
        this.get_list(1, data);
    }
    typeChange(e) {
        this.setState({
            type: e.target.value
        })
        this.get_list(this.state.current, this.state.filter, e.target.value);
    }
    chartChange(e) {
        this.setState({
            chart: e.target.value
        })
    }
    //翻页
    page_up(page) {
        window.scrollTo(0, 0);
        this.setState({
            current: page,
        })
        this.get_list(page, this.state.filter, this.state.type);
    }
    //下载
    download() {
        var filter = this.state.filter, filterStr = "";
        for (var i in filter) {
            filterStr += i + "=" + filter[i] + "&"
        }
        window.open(host + get_finance_download + "?" + filterStr)
    }
    render() {
        const filter = {
            "customer": this.state.customer,
            "product": this.state.product,
            "timeType": this.state.timeType,
            "data-source": this.filter,
            "data-get": this.get_filter.bind(this),
            "thirdType": this.state.thirdType,
            timeGranularity: "MONTH",
            time: [moment().startOf("year"), moment()],
            dimension: "NONE",
            manager: this.state.manager
        }
        return (
            <div>
                <Filter {...filter} />
                <div className="Component-body" >
                    <span style={{ display: "inline-block", width: "100%", textAlign: "right", marginBottom: "10px" }}>

                        <Radio.Group buttonStyle="solid" size="small" onChange={this.chartChange.bind(this)} value={this.state.chart}>
                            <Radio.Button value="column">柱状图</Radio.Button>
                            <Radio.Button value="line">折线图</Radio.Button>
                        </Radio.Group>

                        {/* {this.state.type==="table"?
                        <Button type="primary" size="small" onClick={this.download.bind(this)} style={{ marginRight: "10px" }} >下载</Button>
                    :null}
                        <Radio.Group buttonStyle="solid" size="small" style={{ marginRight: "0" }} onChange={this.typeChange.bind(this)} value={this.state.type}>
                            <Radio.Button value="table">表格</Radio.Button>
                            <Radio.Button value="chart">图表</Radio.Button>
                        </Radio.Group> */}
                    </span>
                    <Spin spinning={this.state.spinning}>
                        <Chart type={this.state.chart} data={this.state.data} dimension={this.state.filter.dimension} loading={this.state.spinning} />
                    </Spin>

                </div>
            </div>
        )
    }
}

export default ApplyTab;