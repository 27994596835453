import React, { Component } from 'react';
import { Route, Redirect,Switch } from 'react-router-dom';
// import Host_monitor from '../components/tab/applyTest';
// import Host_monitor from '../components/list/applyContract';
// import Loan_list from '../components/list/loanCensus'; 

// import Apply_echart from '../components/listEcharts/applyCensus';
// import Loan_echart from '../components/listEcharts/loanCensus';
// import Repay_echart from '../components/listEcharts/repayCensus';
// import LoanStatusChartPage from '../components/list/loanStatusChart';
// import BusinessChartPage from '../components/list/businessChart';
// import LoanChartPage from '../components/list/loanChart';
// import repayChartPage from '../components/list/repayChart';
// import ApplyChartPage from '../components/list/applyChart';


//source
import blzSource from '../components/blz/source/index';
import blzSourceSupplier from '../components/blz/source_supplier/index';
import blzProduct from '../components/blz/product/index';
import blzFinanceTotal from '../components/blz/finance/total/index';
import blzFinanceSort from '../components/blz/finance/sort/index';
import blzFinanceList from '../components/blz/finance/list/index';
import blzCustomerGroupMulti from '../components/blz/customer_group_multi/index';

import pudaoSource from '../components/pudao/source/index';
import pudaoSourceSupplier from '../components/pudao/source_supplier/index';
import pudaoProduct from '../components/pudao/product/index';
import pudaoFinanceTotal from '../components/pudao/finance/total/index';
import pudaoFinanceSort from '../components/pudao/finance/sort/index';
import pudaoFinanceList from '../components/pudao/finance/list/index';

import Nofound from '../components/notFound';
export default class CRouter extends Component {
    componentWillMount() {
        this.router_info = [
            {
                path: "/",
                components: blzSource
            },
            {
                path: "/blz/source",
                components: blzSource
            },
            {
                path: "/blz/source_supplier",
                components: blzSourceSupplier
            },
            {
                path: "/blz/product",
                components: blzProduct
            },
            {
                path: "/blz/finance_total",
                components: blzFinanceTotal
            },
            {
                path: "/blz/finance_sort",
                components: blzFinanceSort
            },
            {
                path: "/blz/finance_list",
                components: blzFinanceList
            },
            {
                path: "/blz/customer_group_multi",
                components: blzCustomerGroupMulti
            },
            {
                path: "/pudao/source",
                components: pudaoSource
            },
            {
                path: "/pudao/source_supplier",
                components: pudaoSourceSupplier
            },
            {
                path: "/pudao/product",
                components: pudaoProduct
            },
            {
                path: "/pudao/finance_total",
                components: pudaoFinanceTotal
            },
            {
                path: "/pudao/finance_sort",
                components: pudaoFinanceSort
            },
            {
                path: "/pudao/finance_list",
                components: pudaoFinanceList
            },
            
        ]
    }
    render() {
        var pathname=window.location.pathname;
        return (
            <div>
                {pathname==="/"?<Redirect to="/blz/product"></Redirect>:null}
                <Switch>
                {
                    this.router_info.map((i, k) => {
                        return <Route path={i.path} breadcrumbName="Home" exact component={i.components} key={k} />
                    })
                }
                    <Route component={Nofound} exact />
                </Switch>
            </div>

        )
    }
}