import React, { Component } from 'react';
import Column from '../../templates/chartColumn';
import Line from '../../templates/chartLine';
class DemoColumn extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.chartArr = [
      {
        yField: "totalCount",
        title: "总条数"
      },
      {
        yField: "successCount",
        title: "成功条数"
      },
      {
        yField: "payCount",
        title: "付费条数"
      },
      {
        yField: "payRate",
        title: "付费率",
        rate: true
      },
      {
        yField: "failRate",
        title: "失败率",
        rate: true
      },
    ]
  }
  componentWillMount() {

  }

  render() {
    var arr = this.props.data;
    var Modals = this.props.type === "column" ? Column : Line
    return <div style={{ overflow: "hidden" }}>
      {
        this.chartArr.map((i,k)=>{
          return <Modals data={arr} xField="date" yField={i.yField}  title={i.title} rate={i.rate} source loading={this.props.loading} />
        })
      }
    </div>;
  }
};

export default DemoColumn;
