import React, { Component } from 'react';
import { Line } from '@ant-design/charts';
import { FullscreenOutlined, TableOutlined } from '@ant-design/icons';
// import {Modal} from 'antd';
import { Modal, Table } from 'antd';
class DemoColumn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    this.columns = [
      {
        title: "时间",
        dataIndex: props.xField
      },
      {
        title: props.source ? "数据源" : "第三维度",
        dataIndex: props.seriesField
      },
      {
        title: props.title,
        dataIndex: props.yField
      },
    ]
  }
  enlarge(large) {
    this.setState({
      visible: true,
      large: large
    })
  }
  cancel() {
    this.setState({
      visible: false
    })
  }
  render() {
    var data=this.props.data;
    var dataFinal=[];
    if(this.props.search){
      data.forEach(item=>{
        var arr=item.data;
        var total=0;
        arr.forEach(ele=>{
          total+=(ele[this.props.yField])
        })
        if(total){
          dataFinal.push.apply(dataFinal,arr)
        }
      })
    }
    const config = {
      data: this.props.search?dataFinal:this.props.data,
      xField: this.props.xField,
      yField: this.props.yField,
      seriesField: this.props.seriesField||null,
      // color: ['#1ca9e6', '#f88c24'],
      // label: {
      //   // content: item => this.props.rate ? (item[this.props.yField]).toFixed(2) + "%" : item[this.props.yField],
      // },
      loading:this.props.loading,
      height: 300,
      meta: {
        [this.props.yField]: { alias: this.props.title },
        [this.props.yField]: { formatter: e => { return this.props.rate ? e + "%" : e } },
        [this.props.xField]: this.props.data.length > 0 ? { formatter: e => { return e.replace(/_/g, "-") } } : null
      },
      yAxis: {
        max: this.props.rate ? 100 : null,
      },
      xAxis: {
        label: {
          // autoRotate: false,
          formatter: e => {
            return e.replace(/_/g, "-")
          }
        },
      },
      slider: {
        start: 0,
        end: 1,
        formatter:e=>{ return e.replace(/_/g,"-")} 
      },
      
    };
    const modalInfo = {
      visible: this.state.visible,
      title: this.props.title,
      footer: null,
      onCancel: this.cancel.bind(this),
      width: "90%"
    }
    return <div style={{ width: "50%", padding: "10px 20px", float: "left" }}>
      <div className="chartTitle">{this.props.title}
        <FullscreenOutlined style={{ float: "right" }} onClick={() => { this.enlarge(true) }} />
        <TableOutlined style={{ float: "right", marginRight: "10px" }} onClick={() => { this.enlarge(false) }} />
      </div>
      {this.props.search?dataFinal.length>0?<Line {...config} />:null:<Line {...config} />}
      <Modal {...modalInfo}>{this.state.large ? <Line {...config} /> : <Table dataSource={this.props.data} columns={this.columns} pagination={false} />}</Modal>
    </div>;
  }
};
export default DemoColumn;
