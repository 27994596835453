import React, { Component } from 'react';
import Filter from '../../../templates/Filter_obj8';
import { Radio,Spin} from 'antd';
import Chart from './chart';
import {axios_f} from '../../../../ajax/request';
import {dropdown_datasource_time,get_finance_sort,dropdown_datasource_code,get_finance_download} from '../../../../ajax/api';
import {page,host} from '../../../../ajax/config';
import moment from 'moment';
import {accDiv} from '../../../../ajax/tool';
class ApplyTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chart:"column",
            type:"chart",
            pageSize: page.size,
            data: [],
            list: [],
            listPage: 0,
            current: 1,
            filter:{
                beginDate:moment().startOf("year").format("YYYY-MM-DD"),endDate:moment().format("YYYY-MM-DD"),
                dimension:"CUSTOMER"
            },
            xName:"产品"
        };
    }
    componentWillMount() {
        this.get_select();
        this.get_list(1,this.state.filter);
        this.filter = {
            time:{
                name:"时间",
                type:"time",
                default:[moment().startOf("year"),moment()]
            },
            productId: {
                name: "产品名称",
                type: "select",
                values:"product"
            },
            customerId: {
                name: "客户名称",
                type: "select",
                values:"customer"
            },
            managerId: {
                name: "客户经理",
                type: "select",
                values:"manager"
            },
            dimension: {
                name: "X轴",
                type: "select",
                values: "thirdType",
                allHide:true,
                resetValue:"CUSTOMER"
            },
        }
        this.columns=[
            {
                title:"序号",
                // dataIndex:"",
                render:(data,e,index)=>{
                    return index+1
                }
            },
            {
                title:"时间",
                dataIndex:"statsTime",
            },
            {
                title:this.state.thirdName,
                dataIndex:"dimensionName",
                className:"columnHide",
                render:e=>e||"--"
            },
            {
                title:"总条数",
                dataIndex:"totalCount",
            },
            {
                title:"计费条数",
                dataIndex:"chargedCount",
                
            },
            {
                title:"收入",
                dataIndex:"revenue",
                
            },
            {
                title:"成本",
                dataIndex:"cost",
                
            },
            {
                title:"毛利",
                dataIndex:"profit",
                
            },
            {
                title:"毛利率",
                dataIndex:"profitRate",
                render:(data)=>{
                    return data+"%"
                }
            }
        ]
    }
    get_list(page_no = 1, filter = {},type=this.state.type){
        let rqd = JSON.parse(JSON.stringify(filter)),param={};
        rqd.statsEnv = 'BLZ';
        param={...rqd}
        // let type=type||this.state.type
        if(type==="table"){
            param.page = page_no;
            param.size = page.size;
        }
        // param.filter = JSON.stringify(rqd);
        this.setState({
            spinning:true
        })
        axios_f.post(get_finance_sort,param).then(e=>{
            if(!e.code){
                var data=e.data.list;console.log(data)
                for(var i in data){
                    for(var j in data[i]){
                      if(j.indexOf("Rate")!==-1){
                        data[i][j]=Number((data[i][j]*100).toFixed(3))
                      }else{
                        if(j==="revenue"||j==="cost"||j==="profit"){
                            data[i][j]=Number(accDiv(data[i][j],1000))
                        }
                    }
                    }
                  }
                this.setState({
                    data:data,
                    current:e.data.page||1,
                    pageTotal:e.data.total,
                    spinning:false,
                })
            }
        }).catch(e=>{
            this.setState({
                data:[],
                spinning:false
            })
        })
      }
    get_select(){
        axios_f.get(dropdown_datasource_code).then(e=>{
            if(!e.code){
                this.setState({
                    manager:e.data.manager,
                    product:e.data.product,
                    customer:e.data.customer
                })
            }
        })
        axios_f.get(dropdown_datasource_time).then(e=>{
            if(!e.code){
                var data=e.data.dimension,value=[];
                data.forEach(i=>{
                    if(i.value!=="NONE"&&i.value!=="PRODUCT_APP"){
                        value.push(i);
                    }
                })
                this.setState({
                    thirdType:value,
                    timeType:e.data.timeGranularity
                })
            }
        })
        // axios_f.get(dropdown_time).then(e=>{
        //     if(!e.code){
        //         this.setState({
        //             timeType:e.data
        //         })
        //     }
        // })
        // axios_f.get(dropdown_product).then(e=>{
        //     if(!e.code){
        //         this.setState({
        //             product:e.data
        //         })
        //     }
        // })
        // axios_f.get(dropdown_customer).then(e=>{
        //     if(!e.code){
        //         this.setState({
        //             customer:e.data
        //         })
        //     }
        // })
    }
    get_filter(data) {
        this.state.thirdType.forEach(item=>{
            if(item.val===data.dimension){
                this.setState({
                    xName:item.name
                })
            }
        })
        this.setState({
            filter: data,
            current:1
        })
        this.get_list(1,data);
    }
    typeChange(e){
        this.setState({
            type:e.target.value
        })
        this.get_list(this.state.current,this.state.filter,e.target.value);
    }
    chartChange(e){
        this.setState({
            chart:e.target.value
        })
    }
    //翻页
    page_up(page){
        window.scrollTo(0, 0);
        this.setState({
            current: page,
        })
        this.get_list(page,this.state.filter,this.state.type);
    }
    //下载
    download(){
        var filter=this.state.filter,filterStr="";
        for(var i in filter){
            filterStr+=i+"="+filter[i]+"&"
        }
        window.open(host+get_finance_download+"?"+filterStr)
    }
    render() {
        const filter={
            "customer":this.state.customer,
            "product":this.state.product,
            "timeType":this.state.timeType,
            "data-source":this.filter,
            "data-get":this.get_filter.bind(this),
            "thirdType":this.state.thirdType,
            timeGranularity:"DAY",
            time:[moment().startOf("year"),moment()],
            dimension:"CUSTOMER",
            manager:this.state.manager
        }
        return (
            <div>
                <Filter {...filter} />
                <div className="Component-body" >
                    <span style={{ display: "inline-block", width: "100%", textAlign: "right",marginBottom:"10px" }}>
                    {this.state.type==="chart"?
                        <Radio.Group buttonStyle="solid" size="small" onChange={this.chartChange.bind(this)} value={this.state.chart}>
                            <Radio.Button value="column">升序</Radio.Button>
                            <Radio.Button value="line">降序</Radio.Button>
                        </Radio.Group>
                    :null}
                    </span>
                    <Spin spinning={this.state.spinning}>
                    <Chart type={this.state.chart} data={this.state.data} xName={this.state.xName} loading={this.state.spinning} />
                    </Spin>
                    
                </div>
            </div>
        )
    }
}

export default ApplyTab;